import { createRouter, createWebHashHistory } from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Logout from '../views/Logout.vue'
import InterpellationView from '../views/interpellation/InterpellationView.vue'
import InterpellationCreate from '../views/interpellation/InterpellationCreate.vue'
import InterpellationGetFile from '../views/interpellation/InterpellationGetFile.vue'

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true,
            title: "Strona domowa"
        },
    },
    {
        path: '/user/logout',
        name: 'Logout',
        component: Logout,
        meta: {
            requiresAuth: true,
            title: "Wylogowywanie"
        },
    },
    {
        path: '/interpellation/view/:id',
        name: 'InterpellationView',
        component: InterpellationView,
        meta: {
            requiresAuth: true,
            title: "Podgląd interpelacji"
        },
    },
    {
        path: '/interpellation/getfile/:clientTenureID/:interpellationID/:answerID/:userID/:userCode',
        name: 'InterpellationGetFile',
        component: InterpellationGetFile,
        meta: {
            requiresAuth: true,
            title: "Pobierz plik"
        },
    },
    //{
    //    path: '/interpellation/getfile/:answerID/:userID',
    //    name: 'InterpellationGetFile',
    //    component: InterpellationGetFile,
    //    meta: { requiresAuth: true },
    //},
    {
        path: '/interpellation/create',
        name: 'InterpellationCreate',
        component: InterpellationCreate,
        meta: {
            requiresAuth: true,
            title: "Dodaj interpelacji"
        },
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true,
            title: "Strona domowa"
        },
    },
    //{
    //    path: '/login',
    //    name: "Login",
    //    component: Login,
    //    meta: { guest: true },
    //}
]
const router = createRouter({
    //mode: 'history',
    history: createWebHashHistory(),
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    document.title = "Interpelacje eSesja"

    if (to.meta.title)
        document.title = to.meta.title

    next();
});
//router.beforeEach((to, from, next) => {
//    try {
//        if (to.matched.some( (record)  => record.meta.requiresAuth)) {
//            if (store.state.auth.status.loggedIn) {
//                next()
//                return
//            }
//            next('/login')
//        }
//        else {
//            next();
//        }
//    }
//    catch (exp) {
//        console.log("error");
//        console.error(exp);
//    }
//})


export default router
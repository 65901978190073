<template>
    <!-- Item-->
    <a class="list-group-item list-group-item-action lh-tight" v-bind:class="{ 'active': isActive()}"  @click="selectedItem(id)">
        <div class="d-flex w-100 align-items-center justify-content-between">
            <strong class="mb-1">
                {{title}}
            </strong>
            <span class="badge badge-lg"  v-bind:class="{ 'bg-success': isStatusSuccess(), 'bg-danger': isStatusDanger()}">{{answerCount}}</span>

        </div>
        <div class="col-10 mb-1 small">
            {{formatDate(date)}}
        </div>
    </a>
    <!-- /Item-->
</template>
<script>
    export default {
        props: {
            id: {
                type: Number,
                required: true
            },
            title: {
                type: String,
                required: true
            },
            date: {
                type: String,
                required: true
            },
            answerCount: {
                type: Number,
                required: true
            },
            status: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                isSuccess: false,
                isDanger: false,
                showError: false,
            };
        },
        computed: {
            currentID() {
                return parseInt(this.$route.params.id);
            },
        },
        //computed: {
        //    username() {
        //        return this.$store.state.auth.user.username;
        //    }
        //},
        methods: {
            isStatusSuccess() {
                if (this.status == 3)
                    return true;

                return false;
            },
            isStatusDanger() {
                return !this.isStatusSuccess();
            },

            isActive() {
                if (this.currentID == this.id)
                    return true
                return false
            },
            formatDate(val) {
                return this.$moment(val).format('LL');
            },

            selectedItem(requestID) {
                if (!Number.isNaN(requestID))
                    this.$router.push({ name: 'InterpellationView', params: { id: requestID } })
            }
        }
    }
</script>

<template>
    <section class="h-100 login-panel">
        <div class="container h-100">
            <div class="row justify-content-sm-center h-100">
                <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
                    <div class="text-center my-5 login-panel-image">
                        <img :src="`${publicPath}logo/esesja_logo_poziom2.png`" alt="eSesja">
                    </div>
                    <div class="card shadow-lg">
                        <div class="card-body p-5">
                            <div class="row" v-if="showError">
                                <div class="col-12">
                                    <div class="alert alert-danger d-flex align-items-center" role="alert">
                                        <div>
                                            {{message}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="showAutologinInfo()">
                                <div class="col-12">
                                    <div class="alert alert-info d-flex align-items-center" role="alert">
                                        <div class="text-center">
                                            Trwa automatyczne logowanie
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h1 class="fs-4 card-title fw-bold mb-4 text-center">Panel logowania </h1>
                            <form method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="submit">
                                <div class="mb-3">
                                    <label class="text-muted" for="username">Login</label>
                                    <div class="input-group">
                                        <span class="input-group-text" id="addon-user">
                                            <font-awesome-icon :icon="['fas', 'user']" />
                                        </span>
                                        <input id="username" type="email" class="form-control" name="username" v-model="form.username" required autofocus :disabled="disableForm()">
                                    </div>
                                </div>

                                <div class="mb-3">
                                    <div class="w-100">
                                        <label class="text-muted" for="password">Password</label>
                                    </div>
                                    <div class="input-group">
                                        <span class="input-group-text" id="addon-user">
                                            <font-awesome-icon :icon="['fas', 'key']" />
                                        </span>
                                        <input id="password" type="password" class="form-control" name="password" v-model="form.password" required :disabled="disableForm()">
                                    </div>
                                </div>

                                <div class="d-flex align-items-center">
                                    <div class="form-check">
                                        <input type="checkbox" name="remember" id="remember" class="form-check-input" v-model="form.rememberMe">
                                        <label for="remember" class="form-check-label">Zapamiętaj mnie</label>
                                    </div>
                                    <button type="submit" class="btn btn-primary ms-auto" :disabled="disableForm()">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading" ></span>
                                        Login
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        name: 'LoginForm',
        computed: {
            loggedIn() {
                return this.$store.state.auth.status.loggedIn;
            },
            autologin() {
                return this.$store.state.auth.autologin;
            },
            userID() {
                try {
                    return this.$store.state.auth.user.userID;
                }
                catch {
                    return 142;
                }
            },
            autocode() {
                try {
                    return this.$store.state.auth.user.autocode;
                }
                catch {
                    return 'test'
                }
            },
        },
        data() {
            return {
                form: {
                    username: "",
                    password: "",
                    rememberMe: false
                },
                showError: false,
                publicPath: process.env.BASE_URL,
                loading: false
            };
        },

        created() {
            if (this.autologin) {
                this.proceedAutologin()
            }
        },

        methods: {
            showAutologinInfo() {
                return (this.autologin && !this.showError)
            },
            disableForm() {
                return (this.showAutologinInfo() || this.loading)
            },
            async submit() {
                this.loading = true;
                const User = {
                    Username: this.form.username,
                    Password: this.form.password,
                    RemeberMe: this.form.rememberMe
                }
                this.$store.dispatch("auth/login", User).then(
                    () => {
                        this.$router.push("/dashboard");
                    },
                    (error) => {
                        this.loading = false;
                        this.showError = true;
                        this.message = error.data.message                            
                    }
                );
            },
            async proceedAutologin() {
                const Req = {
                    Id: this.userID,
                    Autocode: this.autocode
                };

                this.$store.dispatch("auth/autologin", Req).then(
                    () => {
                        this.$router.push("/dashboard");
                    },
                    (error) => {
                        this.loading = false;
                        this.showError = true;
                        this.message = error.data.message
                    });
            }
        }
    }
</script>
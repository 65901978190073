<template>
    <!-- Error parsed Form-->
    <div class="row" id="errorComponent">
        <div class="col-12">
            <div class="alert alert-danger d-flex align-items-center alert-dismissible fade show" role="alert">
                <font-awesome-icon :icon="['fas', 'triangle-exclamation']" class="bi flex-shrink-0 me-2" width="44" height="44" style="font-size: 30px" />
                <div>{{ message }}</div>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        </div>
    </div>
    <!-- /Error parsed Form-->
</template>
<script>
    export default {
        props: {
            message: {
                type: String,
                required: true
            }
        },
        data() {
            return {
            };
        },
        computed: {
           
        },
        methods: {
           
        }
    }
</script>

import api from "./api";
import url from "./url";

class FileService {
    GetFile(request) {

        return api.get(url.file.getFile, {
            params: {
                ClientTenureID: request.ClientTenureID,
                InterpellationID: request.InterpellationID,
                AnswerID: request.AnswerID,
            },
            responseType: 'blob'
        }).then((response) => {
            return response;
        })
    }

    GetFileSignature(file) {
        return api.post(url.file.getFileSignature, file)
            .then((response) => {
                return response;
            })
    }
}

export default new FileService();
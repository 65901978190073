import api from "./api";
import url from "./url";
class InterpellationService {
    GetAllByUser() {
        return api.get(url.interpellation.getAllByUser)
            .then((res) => {
                return res;
            })
    }

    GetById(request) {
        return api.get(url.interpellation.getById,
            {
                params: {
                    InterpellcationID: request.interpellcationID
                }
            })
            .then((res) => {
                return res;
            })
    }

    AddAswer(request) {
        return api.post(url.interpellation.addAsnwer, request)
            .then((res) => {
                return res;
            })
    }
    AddNew(request) {
        return api.post(url.interpellation.addInterpellation, request)
            .then(
                (res) => {
                    return res;
                }
            )
            .catch((err) => {
                throw err;
            })
    }
    GetTypes() {
        return api.get(url.interpellation.getTypes)
            .then((res) => {

                return res;
            })

    }
}

export default new InterpellationService();
<template>
    <div class="row">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid">
                <div class="navbar" id="navbarText">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {{username}}
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <li>
                                    <router-link to="/user/logout" class="dropdown-item">Wyloguj</router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <span class="navbar-text pull-right">
                    {{clientName}}
                </span>
            </div>
        </nav>
    </div>
</template>
<script>
    export default {
        name: 'NavBar',
        computed: {
            username() {
                return this.$store.state.auth.user.username;
            },
            clientName() {
                return this.$store.state.auth.user.client.name;
            }
        },
        methods: {
            async logout() {
                this.$router.push('/login')
            }
        },
    }
</script>

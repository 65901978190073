import { createStore } from "vuex";
import { auth } from './modules/auth';
import { file } from './modules/file';
import { interpellation } from './modules/interpellation.js'


const store = createStore({
    modules: {
        auth,
        file,
        interpellation
    }
});

export default store;
////import { createStore } from 'vuex'
////import  createPersistedState  from "vuex-persistedstate";
////import auth from './modules/auth';

////// Load Vuex
//////Vue.use(Vuex);
//////// Create store
//////export default new Vuex.Store({
//////    modules: {
//////        auth
//////    },
//////    plugins: [createPersistedState()]
//////});


////// Create a new store instance.
////const store = createStore({
////    modules: {
////        auth
////    },
////    plugins: [createPersistedState()]
////})
////export default store;
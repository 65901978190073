import FileService from '../../services/file.service';

//const user = JSON.parse(localStorage.getItem('user'));

export const file = {
    namespaced: true,
    state: null,
    actions: {
        getFile({ commit }, req) {
            return FileService.GetFile(req).then(
                file => {
                    commit("nothing")
                    return Promise.resolve(file)
                },
                err => {
                    return Promise.reject(err.response)
                }
            );
        }
    },
    mutations: {
        nothing() {

        }
    }
};
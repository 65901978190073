const url = {
    auth: {
        login: "/auth/User/Login",
        logout: "/auth/User/Logout",
        autologin: "/auth/User/Autologin",
    },
    interpellation: {
        getAllByUser: "/Interpellation/GetInterpollationsByUser",
        getById: "/Interpellation/GetInterpollationById",
        addAsnwer: "/Interpellation/AddAsnwer",
        getTypes: "/Interpellation/GetInterpellationType",
        addInterpellation: "/Interpellation/AddNewInterpellation"
    },
    file: {
        getFile: "/File/GetFile",
        getFileSignature: "/File/GetFileSignature"
    }
};

export default url;
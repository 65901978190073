<template>
    <h1>Działa</h1>
</template>
<script>
    export default {
        name: 'InterpellationGetFile',
        computed: {
        },
        data() {
            return {
               
            };
        },
        components: {
        },
        created() {
        },
        watch: {
        },
        methods: {
           
        }
    }
</script>
import InterpellationService from '../../services/interpellation.service';

//const user = JSON.parse(localStorage.getItem('user'));
//const initialState = user
//    ? { status: { loggedIn: true }, user }
//    : { status: { loggedIn: false }, user: null };

export const interpellation = {
    namespaced: true,
    state: null,
    actions: {
        getAllByUser({ commit }, request) {
            commit("com");
            return InterpellationService.GetAllByUser(request)
                .then(
                    lists => {
                        return Promise.resolve(lists.data);
                    },
                    err => {
                        return Promise.reject(err.response);
                    }
                );
        },
        getByID({ commit }, request) {
            commit("com");
            return InterpellationService.GetById(request)
                .then(
                    lists => {
                        return Promise.resolve(lists.data);
                    },
                    err => {
                        return Promise.reject(err.response);
                    }
                );
        },
        addAswer({ commit }, request) {
            commit("com");
            return InterpellationService.AddAswer(request)
                .then(
                    lists => {
                        return Promise.resolve(lists.data);
                    },
                    err => {
                        return Promise.reject(err.response);
                    }
                );
        },
        getTypes({ commit }) {
            commit("com");
            return InterpellationService.GetTypes()
                .then(
                    res => {
                        return Promise.resolve(res.data)
                        
                    },
                    err => {
                        return Promise.reject(err.response)
                    }
                );
        },
        addNewInterpellation({ commit }, request) {
            commit("com");
            return InterpellationService.AddNew(request)
                .then(
                    ret => {
                        return Promise.resolve(ret.data);
                    },
                    err => {
                        return Promise.reject(err.response);
                    }
                );
        },
    },
    mutations: {
        com() {

        }
        //loginSuccess(state, user) {
        //    state.status.loggedIn = true;
        //    state.user = JSON.parse(localStorage.getItem('user'));;
        //},
        //loginFailure(state) {
        //    state.status.loggedIn = false;
        //    state.user = null;
        //},
        //logout(state) {
        //    state.status.loggedIn = false;
        //    state.user = null;
        //},
        //registerSuccess(state) {
        //    state.status.loggedIn = false;
        //},
        //registerFailure(state) {
        //    state.status.loggedIn = false;
        //},
        //refreshToken(state, accessToken) {
        //    state.status.loggedIn = true;
        //    state.user = { ...state.user, accessToken: accessToken };
        //}
    }
};

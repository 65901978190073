<template>
    <!-- Page Head-->
    <div class="row">
        <div class="col-12">
            <div class="page-header" v-if="!isTitle()">
                <h1>{{header.type.name}} z dnia {{formatDate(header.date)}}</h1>
            </div>
            <div class="page-header" v-if="isTitle()
                 ">
                <h1>{{header.title}}</h1>
            </div>
        </div>
    </div>
    <!-- /Page Head -->
</template>
<script>
    export default {
        props: {
            header: {
                type: Object,
                default: () => ({
                    date: '',
                    type: {
                        name: ''
                    },
                    title: '',
                    isPublic: false,
                })
            },
        },
        data() {
            return {

            }
        },
        //computed: {
        //    username() {
        //        return this.$store.state.auth.user.username;
        //    }
        //},
        methods: {
            formatDate(val) {
                return this.$moment(val).format('LL');
            },
            isTitle() {
                if (typeof this.header.title !== 'undefined' && this.header.title) {
                    return true
                }
                return false;
            }
        }
    }
</script>

<template>
    <div class="home">
        <h1>Witamy w aplikacji Interpelacje</h1>
        <p>Wybierz opcję z menu po lewej stronie, aby rozpocząć.</p>
    </div>
</template>
<script>
    export default {
        components: {
        },
        data() {
            return {
            }
        },
        methods: {
        }
    }
</script>
import axiosInstance from "./api";
import TokenService from "./token.service";

const setup = () => {
    axiosInstance.interceptors.request.use(
        (config) => {
            const token = TokenService.getLocalAccessToken();
            if (token) {
                config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
            }
            return config;
        },
        (error) => {
            console.log(error)
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            //router.push({ path: '/user/logout' })
            //const originalConfig = err.config;

            //if (originalConfig.url !== "/auth/signin" && err.response) {
            //    // Access Token was expired
            //    if (err.response.status === 401 && !originalConfig._retry) {
            //        if (TokenService.getAutologin() == true) {

            //            originalConfig._retry = true;

            //            try {
            //                const rs = await axiosInstance.post("/auth/User/RefreshToken", {
            //                    Token: TokenService.getLocalAccessToken(),
            //                    RefreshToken: "test1234",
            //                });

            //                const newDataToken = rs.data;
            //                TokenService.setUser(newDataToken)

            //                store.dispatch('auth/refreshToken');

            //                //TokenService.updateLocalAccessToken(accessToken);

            //                return axiosInstance(originalConfig);
            //            } catch (_error) {
            //                router.push({ path: '/user/logout' })
            //                return Promise.reject(_error);
            //            }
            //        }
            //        else {
            //            router.push({ path: '/user/logout' })
            //        }
            //    }
            //}

            return Promise.reject(err);
        }
    );
};

export default setup;
import { encryptStorage } from "./encrypt.service"

class TokenService {
    getLocalRefreshToken() {
        const user = this.getUser();
        console.log(user);
        return user?.token.refreshToken;
    }

    getLocalAccessToken() {
        const user = this.getUser();
        return user?.token.token;
    }

    updateLocalAccessToken(token) {
        let user = encryptStorage.getItem("user")
        user.token = token;
        encryptStorage.setItem("user", JSON.stringify(user));
    }

    getUser() {
        let decrypt = encryptStorage.getItem("user")
       
        return decrypt;
    }

    setUser(user) {
        encryptStorage.setItem("user", JSON.stringify(user))
    }

    setAutoLogin() {
        encryptStorage.setItem("autologin", true);
    }
    getAutologin() {
        return encryptStorage.getItem("autologin")
    }

    removeUser() {
        encryptStorage.removeItem("user");
        encryptStorage.removeItem("autologin");
    }
}

export default new TokenService();
<template>
    <ErrorParsedForm v-if="errorRequest" :message="errorMessage"></ErrorParsedForm>
    <div class="row inteperllation-add-new" v-if="!checkIsFileIsSign">
        <div class="col-12">
            <div class="page-header">
                <h1>Nowe zapytanie</h1>
            </div>

            <form method="POST" ref="form" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="submit" enctype="multipart/form-data">
                <div class="form-group row">
                    <label for="form-inteperllation-type" class="col-sm-12 col-md-3 col-form-label col-form-label-lg">
                        Typ zapytania
                    </label>
                    <div class="btn-group col-sm-12 col-md-9 inteperllation-type-radio-group" role="group" aria-label="Interpellation Type toggle button group">

                        <div class="radio-button-item" v-for="(item, index) in interpellationTypes" :key="index">
                            <input type="radio" class="btn-check" v-model="formData.type" :id=GetRadioFieldId(item.id) autocomplete="off" :value=item.id @change="typeChange">
                            <!--<input type="radio" class="btn-check" v-model="formData.type" :id=GetRadioFieldId(item.id) autocomplete="off" :value=item.id @change="typeChange">-->
                            <label class="btn btn-outline-primary inteperllation-type-radio-item" :for=GetRadioFieldId(item.id)>{{item.name}}</label>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="interpellationQuestionTitle" class="col-sm-12 col-form-label col-form-label-lg">
                        Tytuł zapytania
                    </label>
                    <div class="col-sm-12">
                        <input type="text" class="form-control form-control-lg" id="interpellationQuestionTitle" v-model="formData.title" :disabled="saving" />
                    </div>
                </div>

                <div class="form-group row">
                    <label for="interpellationQuestionContent" class="col-sm-12 col-form-label col-form-label-lg">
                        Treść
                    </label>
                    <div class="col-sm-12">
                        <textarea class="form-control form-control-lg" id="interpellationQuestionContent" rows="5" v-model="formData.content" :disabled="saving"></textarea>
                    </div>
                </div>

                <div class="form-group row interpellation-file">
                    <div class="col-sm-4 col-md-3">
                        <label for="interpellationFile" class="col-form-label col-form-label-lg">
                            Wybierz plik
                        </label>
                    </div>
                    <div class="col-sm-8 col-md-9">
                        <input class="form-control form-control-lg" id="interpellationFile" name="file" type="file" multiple @change="onFileChange" :disabled="saving">
                    </div>
                </div>
                <div class="row interpellation-answer-panel-item">
                    <div class="col-sm-4 col-md-3">
                        <span>Załączone pliki</span>
                    </div>
                    <div class="col-sm-8 col-md-9">
                        <div class="row" v-if="files.length > 0">
                            <div class="col-md-12">
                                <table class="table table-striped table-hover">
                                    <tbody>
                                        <tr v-for="(item, index) in files" :key="index">
                                            <td>{{item.file.name}}</td>
                                            <td>{{formatBytes(item.file.size)}}</td>
                                            <td><button type="button" class="btn btn-danger " @click="removeFile(item.id)">Usuń</button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row interpellation-answer-panel-item">
                    <div class="col-sm-12">
                        <button type="submit" class="btn btn-primary pull-right" :disabled="saving">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="saving"></span>
                            Dodaj
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="row check-file-sign-info" v-else>
        <div class="col-12">
            <div class="py-5">
                <div class="row justify-content-center h-100">
                    <div class="spinner-border mb-4" style="width: 6rem; height: 6rem;" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <h4 class="text-center">Sprawdzanie pliku</h4>
                    <p class="text-center">{{ currentCheckingFile }}</p>
                </div>
                
            </div>

        </div>
    </div>

</template>
<script>
    import ErrorParsedForm from '@/components/global/ErrorParsedForm.vue';
    import FileService from '@/services/file.service';

    export default {
        name: 'InterpellationCreate',
        computed: {
        },
        data() {
            return {
                checkIsFileIsSign: false,
                checked: false,
                loading: true,
                currentCheckingFile: "",
                interpellationTypes: [],
                formData: {
                    type: 1,
                    title: '',
                    content: '',
                },
                data: new FormData(),
                files: [],
                saving: false,
                errorRequest: false,
                errorTypeRequest: false,
                errorMessage: '',
                errorTypeMessage: '',

            };
        },
        components: {
            ErrorParsedForm
        },
        created() {
            this.FetchInterpellationTypes();
        },

        methods: {
            GetRadioFieldId(name) {
                return "btnradio" + name;
            },
            IsChecked() {
                if (this.checked == false) {
                    this.checked = true;
                    return "checked";
                }
                return "";
            },
            async FetchInterpellationTypes() {
                this.loading = true;
                this.$store.dispatch('interpellation/getTypes')
                    .then((res) => {
                        this.interpellationTypes = res;
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.errorTypeRequest = true;
                        this.errorTypeMessage = error.data.message;
                        this.loading = false;
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            },

            async onFileChange(e) {

                this.errorRequest = false;
                for (var i = 0; i < e.target.files.length; i++) {

                    if (this.formData.type == 2) {
                        try {
                            this.checkIsFileIsSign = true;
                            this.loading = true;
                            let fd = new FormData();
                            this.currentCheckingFile = e.target.files[i].name;
                            fd.append("file", e.target.files[i])
                            let res = (await FileService.GetFileSignature(fd))

                            if (res.data.isValidationForClient == true) {
                                if (res.data.signatures.isValid) {
                                    const file = {
                                        id: this.getUID(),
                                        file: e.target.files[i],

                                    }
                                    this.files.push(file)
                                }
                                else {
                                    this.errorRequest = true;
                                    this.errorMessage = "Pzynajmniej jeden plik nie jest podpisany! Nie został dodany do listy plików.";
                                }
                            }
                            else {
                                const file = {
                                    id: this.getUID(),
                                    file: e.target.files[i],

                                }
                                this.files.push(file)
                            }

                           // console.log(res);
                        }
                        catch (e) {
                           // console.log(e);
                        }
                        finally {
                            this.loading = false;
                            this.checkIsFileIsSign = false;
                        }


                    }
                    else {
                        const file = {
                            id: this.getUID(),
                            file: e.target.files[i],

                        }
                        this.files.push(file)
                    }


                }
                //console.log(this.data)

            },

            async typeChange() {
                let itemToDelete = []
                if (this.formData.type == 2) {

                    this.checkIsFileIsSign = true;

                    for (var i = 0; i < this.files.length; i++) {
                        try {
                            this.loading = true;
                            let fd = new FormData();
                            fd.append("file", this.files[i].file)
                            let res = (await FileService.GetFileSignature(fd));

                            if (res.data.isValidationForClient == true) {
                                if (res.data.signatures.isValid == false) {

                                    itemToDelete.push(this.files[i])
                                    //this.removeItemOnce(this.files, localFileList[i]);

                                    this.errorRequest = true;
                                    this.errorMessage = "Pzynajmniej jeden plik nie jest podpisany! Usunięto z listy plików.";
                                }

                            }
                        }
                        catch (e) {
                            itemToDelete.push(this.files[i])
                            

                        }
                        finally {
                            this.loading = false;
                        }
                    }
                    this.checkIsFileIsSign = false;

                    itemToDelete.forEach((item) => {
                        this.removeItemOnce(this.files, item);
                    })

                }

            },

            async submit() {
                try {
                    this.saving = true;
                    this.resetError();
                    this.resetFormData();
                    this.data.append("type", this.formData.type);
                    this.data.append("title", this.formData.title)
                    this.data.append("message", this.formData.content);

                    this.files.forEach((item) => {
                        this.data.append("files[]", item.file);
                    })

                    this.$store.dispatch('interpellation/addNewInterpellation', this.data)
                        .then((response) => {
                            this.resetData();
                            this.$router.push("/interpellation/view/" + response.id);

                        })
                        .catch((error) => {
                            this.errorRequest = true;
                            this.errorMessage = error.data.message;
                        })
                        .finally(() => {
                            //this.resetData();
                            this.saving = false;
                        });

                }
                catch (err) {
                    //console.log(err);
                }

            },

            removeItemOnce(arr, value) {
                var index = arr.indexOf(value);
                if (index > -1) {
                    arr.splice(index, 1);
                }
                return arr;
            },
            resetError() {
                this.errorRequest = false;
                this.errorMessage = '';
            },
            resetFormData() {
                this.data = new FormData();
            },
            removeFile(id) {
                let element = this.files.find(el => el.id == id);
                this.files = this.files.filter(el => el.id != element.id);
            },
            getUID() {
                let result = '';
                let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let charactersLength = characters.length;
                for (var i = 0; i < 15; i++) {
                    result += characters.charAt(Math.floor(Math.random() *
                        charactersLength));
                }
                return result;
            },
            resetData() {
                this.data = new FormData();
                this.files = [];
                this.formData.type = 1;
                this.formData.content = '';
                this.$refs.form.reset()
            },
            formatBytes(bytes, decimals = 2) {

                if (bytes === 0) return '0 Bytes';

                const k = 1024;
                const dm = decimals < 0 ? 0 : decimals;
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

                const i = Math.floor(Math.log(bytes) / Math.log(k));

                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
            }
        }
    }
</script>
<template>
    <!-- Site logo-->
    <div class="row">
        <div class="col-12 site-logo">
            <img :src="`${publicPath}logo/esesja_logo_poziom2.png`" class="img-fluid" alt="eSesja">
        </div>
    </div>
    <!-- Site logo -->
    <div class="row">
        <div class="col-12">
            <div class="d-flex flex-column align-items-stretch flex-shrink-0 bg-white overflow-auto">
                <div class="list-group list-group-flush list-group-striped">
                    <!-- Item-->
                    <router-link to="/interpellation/create" class="list-group-item list-group-item-action lh-tight">
                        <div class="d-flex w-100 align-items-center justify-content-between">
                            <strong class="mb-1">
                                Nowe zapytanie
                            </strong>
                            <span class="badge badge-lg bg-success">+</span>

                        </div>
                    </router-link>
                    <!--- /Item -->
                    <!--- Item -->
                    <InterpellationListItem v-for="item in listItem"
                                            :key="item.id"
                                            :id="item.id"
                                            :status="item.status"
                                            :title="item.name"
                                            :answerCount="item.answerCount"
                                            :date="item.date">

                    </InterpellationListItem>
                    <!-- /Item-->
                    <div class="loader" v-if="loading">
                        <div class="row justify-content-center h-100">
                            <div class="spinner-border" style="width: 6rem; height: 6rem;" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <span class="text-muted">Wczytywanie...</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import InterpellationListItem from './interpellation/InterpellationListItem.vue'
    import InterpellationService from '../services/interpellation.service';
    export default {
        name: 'SideBar',
        computed: {
            
        },
        components: {
            InterpellationListItem,
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                listItem: [],
                loading: true,
            };
        },
        created() {
            // fetch the data when the view is created and the data is
            // already being observed
            this.loading = true;
            this.fetchInterpellationItems();
        },
        watch: {
            // call again the method if the route changes
            '$route': 'fetchInterpellationItems'
        },
        methods: {
            async logout() {
                this.$router.push('/login')
            },
            async fetchInterpellationItems() {
                try {
                    this.listItem = (await InterpellationService.GetAllByUser()).data
                    this.loading = false;
                    //this.$store.dispatch('interpellation/getAllByUser')
                    //    .then(
                    //        (response) => {
                    //            this.loading = false;
                    //            this.listItem = response;
                    //        },
                    //        (error) => {
                    //            if (error.response.status == 401)
                    //                this.$router.push({ name: 'Logout' })
                    //        }
                    //    )
                    //    .catch((error) => {
                    //        this.loading = false;
                    //        if (error.response.status == 401)
                    //            this.$router.push({ name: 'Logout' })
                    //    })
                    //    .finally(() => {
                    //        this.loading = false;
                    //    });
                }
                catch (e) {
                    this.loading = false;
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Logout' })
                }
               
            }
        },
    }
</script>
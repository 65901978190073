<template>
    <div style="display:none"></div>
</template>
<script>
    export default {
        computed: {
           
        },
        components: {
        },
        data() {
            return {

            }
        },
        created() {
            this.AutoLogin();
        },
        methods: {
            AutoLogin() {

                

                this.$store.dispatch("auth/logout").then(
                    () => {
                        this.$router.push("/");
                        
                    }).catch(
                        () => {

                    }).finally(() => {

                    });
            }
        }
    }
</script>
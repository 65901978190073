<template>
    <!-- Add Item -->
    <div class="row interpellation-answer-panel">
        <ErrorParsedForm v-if="showError" :message="errorMessage"></ErrorParsedForm>
        <div class="col-md-12">
            <form ref="form" method="POST" class="needs-validation" novalidate="" autocomplete="off" @submit.prevent="submit" enctype="multipart/form-data">
                <div class="row interpellation-answer-panel-header">
                    <div class="col-sm-12">
                        <h4>Panel odpowiedzi</h4>
                    </div>
                </div>
                <div class="row interpellation-answer-panel-item">
                    <div class="col-sm-4 col-md-2">
                        <label for="interpellationMessage" class="col-form-label col-form-label-lg">
                            Odpowiedź
                        </label>
                    </div>
                    <div class="col-sm-8 col-md-10">
                        <textarea class="form-control form-control-lg" id="interpellationMessage" placeholder="Dodaj odpowiedź" rows="3" v-model="form.message" :disabled="saving"></textarea>
                    </div>
                </div>
                <div class="row interpellation-answer-panel-item">
                    <div class="col-sm-4 col-md-2">
                        <label for="interpellationFile" class="col-form-label col-form-label-lg">
                            Wybierz plik
                        </label>
                    </div>
                    <div class="col-sm-8 col-md-10">
                        <input class="form-control form-control-lg" id="interpellationFile" name="file" type="file" multiple @change="onFileChange" :disabled="saving">
                    </div>
                </div>
                <div class="row interpellation-answer-panel-item">
                    <div class="col-sm-4 col-md-2">
                        <span>Załączone pliki</span>
                    </div>
                    <div class="col-sm-8 col-md-10">
                        <div class="row" v-if="files.length > 0">
                            <div class="col-md-12">
                                <table class="table table-striped table-hover">
                                    <tbody>
                                        <tr v-for="item in files" :key="item.id">
                                            <td>{{item.file.name}}</td>
                                            <td>{{formatBytes(item.file.size)}}</td>
                                            <td><button type="button" class="btn btn-danger " @click="removeFile(item.id)" :disabled="saving">Usuń</button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row interpellation-answer-panel-item">
                    <div class="col-sm-12">
                        <button type="submit" class="btn btn-primary pull-right" :disabled="saving">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="saving"></span>
                            Dodaj
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- Add Item -->
</template>
<script>
    import ErrorParsedForm from '@/components/global/ErrorParsedForm.vue';
    export default {
        props: {


        },
        data() {
            return {
                form: {
                    message: "",
                },
                data: new FormData(),
                files: [],
                errorMessage: '',
                showError: false,
                saving: false
            }
        },
        computed: {
           
        },
        components: {
            ErrorParsedForm
        },
        watch: {
            // call again the method if the route changes
            '$route': 'resetData'
        },
        //computed: {
        //    username() {
        //        return this.$store.state.auth.user.username;
        //    }
        //},
        methods: {
            onFileChange(e) {
                for (var i = 0; i < e.target.files.length; i++) {
                    const file = {
                        id: this.getUID(),
                        file: e.target.files[i]

                    }
                    this.files.push(file)
                }
               
            },
            async submit() {
                this.saving = true;
                this.resetError();
                this.resetFormData();
                this.data.append("message", this.form.message);
                this.data.append("interpellationID", this.$route.params.id);
                this.files.forEach((item) => {
                    this.data.append("files[]", item.file);
                })

                this.$store.dispatch('interpellation/addAswer', this.data)
                    .then(() => {
                        this.resetData();
                        this.saving = false;
                        this.$emit('update-interpellation');

                    })
                    .catch((err) => {
                        this.showError = true;
                        this.errorMessage = err.data.message;
                        this.saving = false;
                    })
                    .finally(() => {
                        this.saving = false;
                    })
                
            },
            resetError() {
                this.showError = false;
                this.errorMessage = '';
            },
            resetFormData() {
                this.data = new FormData();
            },
            removeFile(id) {
                let element = this.files.find(el => el.id == id);
                this.files = this.files.filter(el => el.id != element.id);
               
            },
            getUID() {
                let result = '';
                let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let charactersLength = characters.length;
                for (var i = 0; i < 15; i++) {
                    result += characters.charAt(Math.floor(Math.random() *
                        charactersLength));
                }
                return result;
            },
            resetData() {
                this.resetFormData();
                this.files = [];
                this.form.message = '';
                this.$refs.form.reset()
            },
            formatBytes(bytes, decimals = 2) {

                if (bytes === 0) return '0 Bytes';

                const k = 1024;
                const dm = decimals < 0 ? 0 : decimals;
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

                const i = Math.floor(Math.log(bytes) / Math.log(k));

                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
            }
        }
    }
</script>

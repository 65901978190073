<template>
    <main v-if="loggedIn">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-2 col-md-3 col-sm-4 px-0 mwc-wrapper scrollbar-thin scroll-smooth">
                    <SideBar v-if="loggedIn" />
                </div>
                <div class="col-lg-10 col-md-9 col-sm-8 mwc-wrapper scrollbar-thin scroll-smooth">
                    <NavBar v-if="loggedIn" />
                    <div class="row interpellation-page-content">
                        <div class="col-12">
                            <router-view />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </main>
    <LoginForm v-if="notLoggedIn" />
</template>

<script>
    import NavBar from './components/NavBar.vue'
    import SideBar from './components/SideBar.vue'
    import LoginForm from './components/LoginForm.vue'
    export default {
        components: {
            NavBar,
            SideBar,
            LoginForm
        },
        data() {
            return {
                publicPath: process.env.BASE_URL
            };
        },
        computed: {
            loggedIn() {
                return this.$store.state.auth.status.loggedIn;
            },
            notLoggedIn() {
                return !this.$store.state.auth.status.loggedIn;
            }
        },
        methods: {
        }
    }
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }

    main {
        display: flex;
        flex-wrap: nowrap;
        height: 100vh;
        height: -webkit-fill-available;
        max-height: 100vh;
        overflow-x: auto;
        overflow-y: auto;
    }

    .scrollarea {
        overflow-y: auto;
        overflow-x: hidden;
    }


    .mwc-wrapper {
        background: transparent;
        height: 100vh;
        height: -webkit-fill-available;
        max-height: 100vh;
        overflow-y: auto;
    }


    .scrollbar-thin {
        scrollbar-width: thin;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .scroll-smooth {
        scroll-behavior: smooth;
    }

    .badge-lg {
        padding-top: calc(.5rem + 1px);
        padding-bottom: calc(.5rem + 1px);
        font-size: 1.25rem;
    }

    .badge-xl {
        padding-top: calc(.5rem + 1px);
        padding-bottom: calc(.5rem + 1px);
        font-size: 1.85rem;
    }

    .site-logo {
        padding: 10px;
        margin: 10px;
        width: 90%;
    }

    .login-panel .login-panel-image img {
        max-width: 416px;
        padding: 10px;
    }

    .interpellation-page-content {
        margin-top: 20px !important;
    }

        .interpellation-page-content .inteperllation-add-new .inteperllation-type-radio-group .inteperllation-type-radio-item {
            margin-right: 20px;
        }

        .interpellation-page-content .inteperllation-add-new .interpellation-file {
            margin-top: 20px;
        }

    .list-group.list-group-striped .list-group-flush > .list-group-item {
        border-width: 0 0 1px;
        cursor: pointer;
    }

    .list-group.list-group-striped .list-group-item:nth-of-type(odd):not(.active) {
        background-color: rgba(0, 0, 0, .05);
    }

    .list-group.list-group-striped .list-group-item:hover:not(.active) {
        background-color: rgba(0, 0, 0, .075);
    }
    /* Interpellation */
    .interpellation-answers .interpellation-item {
        padding: 10px;
        border-left: 5px solid rgb(0, 0, 0, 0.0);
        border-top: 1px solid #ccc;
    }

        .interpellation-answers .interpellation-item:hover {
            padding: 10px;
            border-left: 5px solid rgb(0, 0, 0, 0.7);
        }

    .interpellation-answers.interpellation-striped .interpellation-item:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .05);
    }

    .interpellation-answers .interpellation-item .interpellation-item-header,
    .interpellation-answers .interpellation-item .interpellation-item-body {
        padding: 10px;
    }

        .interpellation-answers .interpellation-item .interpellation-item-header .interpellation-item-author {
            font-size: 1.5em;
        }

    .interpellation-answer-panel {
        border: 1px solid #ccc;
        background: #e5e5e5;
        margin: 20px !important;
    }

        .interpellation-answer-panel .interpellation-answer-panel-header,
        .interpellation-answer-panel .interpellation-answer-panel-item {
            padding: 10px;
        }

    #errorComponent {
        margin-top: 10px;
    }

        #errorComponent .alert {
            padding: 10px;
        }
    /* /Interpellation */
</style>

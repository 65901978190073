<template>
    <!-- InterpellationAnswearItem-->
    <div class="row interpellation-item">
        <div class="col-md-12">
            <div class="row interpellation-item-header">
                <div class="col-md-12">

                    <span class="interpellation-item-author">{{asnwear.user.name}} {{asnwear.user.surname}}</span>
                    <span class="interpellation-item-addon">, dnia </span>
                    <span class="interpellation-item-date">{{formatDate(asnwear.date)}}</span>
                    <span class="interpellation-item-addon"> o godzinie </span>
                    <span class="interpellation-item-date">{{formatTime(asnwear.date)}}</span>
                </div>
            </div>
            <div class="row interpellation-item-body">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-12">
                            <span class="interpellation-item-content">
                                {{asnwear.content}}
                            </span>
                        </div>
                    </div>
                    <div class="row" v-if="asnwear.isFile">
                        <div class="col-md-12">
                            <!--<router-link :to="{ name: 'InterpellationGetFile', params: { clientTenureID: currentClientTenureID, interpellationID: currentInterpellationID, answerID: asnwear.id, userID: currentUserID, userCode: currentUserCode}}" class="btn btn-primary">
                                <font-awesome-icon :icon="['fas', 'download']" /> Pobierz {{asnwear.file.displayName}} ({{asnwear.file.size}})
                            </router-link>-->
                            <button class="btn btn-primary" @click="downloadFile(currentClientTenureID, currentInterpellationID, asnwear.id)">
                                <font-awesome-icon :icon="['fas', 'download']" /> Pobierz {{asnwear.file.displayName}} ({{asnwear.file.size}})
                            </button>
                            <!--<a :href="getFileAnchor(currentClientTenureID, currentInterpellationID, asnwear.id)" target="_blank" class="btn btn-primary">
                                Pobierz {{asnwear.file.displayName}} ({{asnwear.file.size}})
                            </a>-->


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /InterpellationAnswearItem -->
</template>
<script>
    export default {
        props: {
            asnwear: {
                type: Object,
                default: () => ({
                    content: '',
                    date: '',
                    user: {
                        name: '',
                        surname: '',
                    },
                }),
            },
            currentInterpellationID: {
                type: Number
            }

        },
        data() {
            return {

            }
        },
        computed: {
            currentClientTenureID() {
                return this.$store.state.auth.user.clientTenureID
            }
        },
        methods: {
            formatDate(val) {
                return this.$moment(val).format('LL');
            },
            formatTime(val) {
                return this.$moment(val).format('LT');
            },
            getFileAnchor(clientTenureID, interpellationID, answerID) {
                return window.location.origin + "/api/File/GetFile?ClientTenureID=" + clientTenureID + "&InterpellationID=" + interpellationID + "&AnswerID=" + answerID;
            },
            downloadFile(clientTenureID, interpellationID, answerID) {
                try {
                    const req = {
                        ClientTenureID: clientTenureID,
                        InterpellationID: interpellationID,
                        AnswerID: answerID,
                    }
                    this.$store.dispatch('file/getFile', req)
                        .then((response) => {
                            //console.log(response);
                            var blob = new Blob([response.data],
                                {
                                    type: response.headers['content-type']
                                })

                            var filename = response.headers['content-disposition'].split("filename=")[1];
                            //console.log(filename);
                            filename = filename.substr(0, filename.indexOf(';'));
                            filename = filename.replaceAll('"', '');
                            //console.log(filename);
                            var link = document.createElement('a')
                            link.href = window.URL.createObjectURL(blob)
                            link.download = filename
                            link.click()
                           
                        })
                        .catch((err) => {
                            console.log(err);
                            alert("Problem z pobraniem pliku")
                            //console.log(err);
                        })
                        .finally(() => {

                        });
                }
                catch {
                    //console.log("error");
                }
            }
        }
    }
</script>

import api from "./api";
import url from "./url";

import TokenService from "./token.service";
class AuthService {
    Login(User) {
        const req = {
            Username: User.Username,
            Password: User.Password,
        }
        return api.post(url.auth.login, req)
            .then((response) => {
                if (response.data.token.token) {
                    TokenService.setUser(response.data)
                    if (User.RemeberMe) {
                        TokenService.setAutoLogin()
                    }
                }
            })
    }
    Logout() {
        return api.get(url.auth.logout)
            .then((res) => {
                TokenService.removeUser();
                return res;
            })
        
    }
    Autologin(request) {
        return api.post(url.auth.autologin, request)
            .then((res) => {
                TokenService.removeUser();
                return res;
            })
    }
}

export default new AuthService();